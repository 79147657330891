import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "207-2022-11-08",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#207-2022-11-08",
        "aria-label": "207 2022 11 08 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v2.0.6...v2.0.7"
      }}>{`2.0.7`}</a>{` (2022-11-08)`}</h3>
    <h3 {...{
      "id": "-builddependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies",
        "aria-label": " builddependencies permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`style:`}</strong>{` make global scss variables (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8573604337c437fa1f3f830ba00ebb0b2b071d38"
        }}>{`8573604`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`ga:`}</strong>{` migrate to google analytics 4 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/ecd23cd924255e7971cd71d5f2e7e0111b1ea61c"
        }}>{`ecd23cd`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-styles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-styles",
        "aria-label": " styles permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🎨 Styles`}</h3>
    <ul>
      <li parentName="ul">{`change more styles for branding (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/4f208d75c3ad916769d8dbf51d6c207bdd65f387"
        }}>{`4f208d7`}</a>{`)`}</li>
      <li parentName="ul">{`more branding styles and update snapshots (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8b21b1cb768ede3aa191788e6015bf1e077bb82a"
        }}>{`8b21b1c`}</a>{`)`}</li>
      <li parentName="ul">{`new branding (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/86c71768a78533990005e5e623854906be7560ed"
        }}>{`86c7176`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`vis:`}</strong>{` replace loading image (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/04873d2b92c53d12d2e38d5eb0d84c86c152a86f"
        }}>{`04873d2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-continuous-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration",
        "aria-label": " continuous integration permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`update cancel-workflow action `}{`[skip ci]`}{` (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/6d3379580b6afcadae5a336cd68fbb2b2c083d28"
        }}>{`6d33795`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes",
        "aria-label": " bug fixes permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`issuesreported in sonar cloud (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/5671495bb44d3be088c881920280b210bf827945"
        }}>{`5671495`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`header:`}</strong>{` menu button position (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/2beedab24ff1c7a5c6365534b99dd3127247527b"
        }}>{`2beedab`}</a>{`)`}</li>
      <li parentName="ul">{`more fix according to sonar cloud (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/36166fac9cedd84ac90880cd6eb56ceab84fd5e5"
        }}>{`36166fa`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "206-2022-09-05",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#206-2022-09-05",
        "aria-label": "206 2022 09 05 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v2.0.5...v2.0.6"
      }}>{`2.0.6`}</a>{` (2022-09-05)`}</h3>
    <h3 {...{
      "id": "-bug-fixes-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes-1",
        "aria-label": " bug fixes 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ci:`}</strong>{` npm ci upstream errors (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e865edda78b5f147d1eba4fb0ed67776e3c447d6"
        }}>{`e865edd`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-continuous-integration-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-1",
        "aria-label": " continuous integration 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`reduce cron schedule for deployment (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/1f96c122fe09db90a527e9f35846000098610bff"
        }}>{`1f96c12`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "205-2022-05-24",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#205-2022-05-24",
        "aria-label": "205 2022 05 24 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v2.0.4...v2.0.5"
      }}>{`2.0.5`}</a>{` (2022-05-24)`}</h3>
    <h3 {...{
      "id": "-bug-fixes-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes-2",
        "aria-label": " bug fixes 2 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`hotfix for CI (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/d013ed27b814fd37ba7be330242fcabf86352f27"
        }}>{`d013ed2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "204-2022-05-24",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#204-2022-05-24",
        "aria-label": "204 2022 05 24 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v2.0.3...v2.0.4"
      }}>{`2.0.4`}</a>{` (2022-05-24)`}</h3>
    <h3 {...{
      "id": "-bug-fixes-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes-3",
        "aria-label": " bug fixes 3 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`webhook outcome & visualisation styles (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/3265a1d12f7434cf3ebc53aedf5882e0819ff4eb"
        }}>{`3265a1d`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "203-2022-05-24",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#203-2022-05-24",
        "aria-label": "203 2022 05 24 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v2.0.2...v2.0.3"
      }}>{`2.0.3`}</a>{` (2022-05-24)`}</h3>
    <h3 {...{
      "id": "-builddependencies-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-1",
        "aria-label": " builddependencies 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependencies and add webhook (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/fd91dd3a2081b7ed01f3b23d1dc56f56a910c9fb"
        }}>{`fd91dd3`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "202-2022-05-18",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#202-2022-05-18",
        "aria-label": "202 2022 05 18 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v2.0.1...v2.0.2"
      }}>{`2.0.2`}</a>{` (2022-05-18)`}</h3>
    <h3 {...{
      "id": "-continuous-integration-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-2",
        "aria-label": " continuous integration 2 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`separate build from e2e tests (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/0e18405d484e120bcfd34ca2a9fdaa9a55539f39"
        }}>{`0e18405`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/280"
        }}>{`#280`}</a></li>
      <li parentName="ul">{`zip folder for artifact (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/f65e120646b28f2817bbaf21d290633b4aa4a8b2"
        }}>{`f65e120`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-bug-fixes-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes-4",
        "aria-label": " bug fixes 4 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`visualisation for F-UJI (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/9713bf51830e1b770cad9cc24d83fa678f63de55"
        }}>{`9713bf5`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "201-2022-05-03",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#201-2022-05-03",
        "aria-label": "201 2022 05 03 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v2.0.0...v2.0.1"
      }}>{`2.0.1`}</a>{` (2022-05-03)`}</h3>
    <h3 {...{
      "id": "-builddependencies-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-2",
        "aria-label": " builddependencies 2 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/717326081f03ae81efa9acf586f98839767b02e4"
        }}>{`7173260`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/273"
        }}>{`#273`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/274"
        }}>{`#274`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/275"
        }}>{`#275`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/276"
        }}>{`#276`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/277"
        }}>{`#277`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/278"
        }}>{`#278`}</a></li>
      <li parentName="ul">{`upgrade dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/5df130a39be87a5eea9883aefdf64ada4b590e4f"
        }}>{`5df130a`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "200-2022-04-14",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#200-2022-04-14",
        "aria-label": "200 2022 04 14 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h2" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v1.2.2...v2.0.0"
      }}>{`2.0.0`}</a>{` (2022-04-14)`}</h2>
    <h3 {...{
      "id": "-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-breaking-changes",
        "aria-label": " breaking changes permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`release:`}</strong>{` framework v4.`}</li>
    </ul>
    <h3 {...{
      "id": "-styles-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-styles-1",
        "aria-label": " styles 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🎨 Styles`}</h3>
    <ul>
      <li parentName="ul">{`word cloud (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/099ded4dc2468649e46c3c0209e36677f07146c8"
        }}>{`099ded4`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "️-code-refactoring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%B8%8F-code-refactoring",
        "aria-label": "️ code refactoring permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`♻️ Code Refactoring`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`vis:`}</strong>{` move data out of the production to speed up the building process (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/0e6a8d6cab88d8f53526d8f6cc913488644d457c"
        }}>{`0e6a8d6`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-builddependencies-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-3",
        "aria-label": " builddependencies 3 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`release:`}</strong>{` bump major version number (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/6131366f1899bd20519c4a0c870c47c8e46af448"
        }}>{`6131366`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "122-2022-04-13",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#122-2022-04-13",
        "aria-label": "122 2022 04 13 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v1.2.1...v1.2.2"
      }}>{`1.2.2`}</a>{` (2022-04-13)`}</h3>
    <h3 {...{
      "id": "-builddependencies-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-4",
        "aria-label": " builddependencies 4 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul">{`upgrade Gatsby to v4 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/d58d984db75e0d97b5a3542b64a8c41ae30f2d47"
        }}>{`d58d984`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-styles-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-styles-2",
        "aria-label": " styles 2 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🎨 Styles`}</h3>
    <ul>
      <li parentName="ul">{`blog post share buttons (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/158a4e786594e2a044b6ee6c15e4bbf23be5a353"
        }}>{`158a4e7`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/224"
        }}>{`#224`}</a></li>
      <li parentName="ul">{`search result date (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/4b79ec3e57c588b150ff6d46c99b28bad4000cbb"
        }}>{`4b79ec3`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-continuous-integration-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-3",
        "aria-label": " continuous integration 3 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`replace actions for npm cache (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/fef45382508d2f6df3fc9aaa1b751a3c1080ff7c"
        }}>{`fef4538`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "121-2022-04-13",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#121-2022-04-13",
        "aria-label": "121 2022 04 13 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v1.2.0...v1.2.1"
      }}>{`1.2.1`}</a>{` (2022-04-13)`}</h3>
    <h3 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`📝 Documentation`}</h3>
    <ul>
      <li parentName="ul">{`initial edit to statistical testing (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/336147c662f2949e32b24897279f3f1bed1897ba"
        }}>{`336147c`}</a>{`)`}</li>
      <li parentName="ul">{`initial edit to statistical testing (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/03ec87241b95773244e7d950527c5cd9ee448158"
        }}>{`03ec872`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`learning-path:`}</strong>{` statistical part 4 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/b3b3bca54474304877ea38ef3327e05a5133ba48"
        }}>{`b3b3bca`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-bug-fixes-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes-5",
        "aria-label": " bug fixes 5 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`ant-design chart visualisation canvas issue (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/73ad9d65368c5246c648f56566b6ca759099dda2"
        }}>{`73ad9d6`}</a>{`)`}</li>
      <li parentName="ul">{`change end of line to LF `}{`[skip ci]`}{` (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8f60fafc3bb6d9399424d098fe0051fe44c2ecd1"
        }}>{`8f60faf`}</a>{`)`}</li>
      <li parentName="ul">{`ResizeObserver is not defined error (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/9b57205a8be3cd84dbbff6d333110f4b71824438"
        }}>{`9b57205`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/259"
        }}>{`#259`}</a></li>
    </ul>
    <h3 {...{
      "id": "-builddependencies-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-5",
        "aria-label": " builddependencies 5 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` bump actions/checkout from 2 to 3 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8951b224a50d463f938712905191c20b79b461a5"
        }}>{`8951b22`}</a>{`)`}</li>
      <li parentName="ul">{`update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/84d56c9f6010d5c7b69fc6371b70b389f04b825f"
        }}>{`84d56c9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`deps:`}</strong>{` update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/6aa570b5e750735be3bfbaee21156b79b5709b22"
        }}>{`6aa570b`}</a>{`)`}</li>
      <li parentName="ul">{`upgrade to React 18 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/df6b56d67311a8916ad13d2d665cc43ba80746bb"
        }}>{`df6b56d`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-continuous-integration-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-4",
        "aria-label": " continuous integration 4 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`change runner `}{`[skip ci]`}{` (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/ded3c4a0f37bf0607b9297eda3e0f74ecd13a184"
        }}>{`ded3c4a`}</a>{`)`}</li>
      <li parentName="ul">{`fix ci cache error (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/ae200ada9c4a16b756979a736a8d9bcd4b90bbd7"
        }}>{`ae200ad`}</a>{`)`}</li>
      <li parentName="ul">{`fix script execution (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/d216e03fdf4eb7a73b459d3bd943c2b5b6119c38"
        }}>{`d216e03`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`actions:`}</strong>{` move to windows-2019 for consistency (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/b145e479febbc556ff81889dbdb6ef021c717a8b"
        }}>{`b145e47`}</a>{`)`}</li>
      <li parentName="ul">{`upgrade cache version (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/0c241e0f7b48d27f8411daf127ae6020d30b8daf"
        }}>{`0c241e0`}</a>{`)`}</li>
      <li parentName="ul">{`use actions-gh-pages `}{`[skip ci]`}{` (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/2ccf254f66869c6177a74b1c94aa31f31842de17"
        }}>{`2ccf254`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`live:`}</strong>{` use actions (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/453f244997e6a4a7d87b22e34e7d2f21cc9c31e9"
        }}>{`453f244`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "120-2022-02-23",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#120-2022-02-23",
        "aria-label": "120 2022 02 23 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h2" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v1.1.1...v1.2.0"
      }}>{`1.2.0`}</a>{` (2022-02-23)`}</h2>
    <h3 {...{
      "id": "-builddependencies-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-6",
        "aria-label": " builddependencies 6 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`dependencies:`}</strong>{` add cssnano to minify css bundle (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e32cd98579b7c0c754cbf1caeab2ce88c04e97da"
        }}>{`e32cd98`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`dependencies:`}</strong>{` add gatsby-purge-css (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e5c65041f6cd88c44d142490f2d6f7030dc32391"
        }}>{`e5c6504`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`dependencies:`}</strong>{` migrate tailwindcss from v2 to v3 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/465b26311e7453772fd23b5b2a52206974449dad"
        }}>{`465b263`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/236"
        }}>{`#236`}</a></li>
    </ul>
    <h3 {...{
      "id": "-styles-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-styles-3",
        "aria-label": " styles 3 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🎨 Styles`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`blog:`}</strong>{` adjust margin and font size of headings (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/6ae004b33fe0e4d36dbdb32b21700404cd0a1e09"
        }}>{`6ae004b`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-documentation-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-documentation-1",
        "aria-label": " documentation 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`📝 Documentation`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`learning-path:`}</strong>{` add glueviz and paraview to learning paths (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8aedf0b77f9b682d195d8f0c2e9cdfeb449b2882"
        }}>{`8aedf0b`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-features",
        "aria-label": " features permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✨ Features`}</h3>
    <ul>
      <li parentName="ul">{`reveal changelog page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/df149f28d668ca56e6dca8332415a767fa963650"
        }}>{`df149f2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-continuous-integration-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-5",
        "aria-label": " continuous integration 5 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`rollback to windows-2019 instead of using the latest 2022 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e3859b9a068abd1e4019ca0f32bd1a5244098e34"
        }}>{`e3859b9`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-testing",
        "aria-label": " testing permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✅ Testing`}</h3>
    <ul>
      <li parentName="ul">{`fix blog test issues (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/6fefabda6b4acc9d765c462718721953490daaae"
        }}>{`6fefabd`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`blog:`}</strong>{` remove unnecessary test (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/1c7bf842ae86889dc9d99e60b5f037f225cee052"
        }}>{`1c7bf84`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "111-2022-02-18",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#111-2022-02-18",
        "aria-label": "111 2022 02 18 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v1.1.0...v1.1.1"
      }}>{`1.1.1`}</a>{` (2022-02-18)`}</h3>
    <h3 {...{
      "id": "-documentation-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-documentation-2",
        "aria-label": " documentation 2 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`📝 Documentation`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`blog:`}</strong>{` add Glueviz blog post (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/a82487dc46c109b4ede86fbf20853bf0d065358c"
        }}>{`a82487d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`blog:`}</strong>{` additional content for Glueviz (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/0d4bf664fc5e4c0a564f0c57fb28c5037521c100"
        }}>{`0d4bf66`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "110-2022-02-01",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#110-2022-02-01",
        "aria-label": "110 2022 02 01 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h2" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v1.0.2...v1.1.0"
      }}>{`1.1.0`}</a>{` (2022-02-01)`}</h2>
    <h3 {...{
      "id": "-continuous-integration-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-6",
        "aria-label": " continuous integration 6 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`remove post-deploy test to avoid disruption to GA (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/99b20170213cc574b60473a0fda0fba91cdb14e5"
        }}>{`99b2017`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-bug-fixes-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes-6",
        "aria-label": " bug fixes 6 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`config:`}</strong>{` exclude json files from the source nodes stage" (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/20f5bc135f39d89d79b1ed89150b83619300e60a"
        }}>{`20f5bc1`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-builddependencies-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-7",
        "aria-label": " builddependencies 7 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul">{`limit number of content in the development env (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e3fe7e8dd4b18608624c8d8178ef166c81c5c99a"
        }}>{`e3fe7e8`}</a>{`)`}</li>
      <li parentName="ul">{`resolve package-lock.json file error (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/057b3dc35ee4663e00e94c85831d68ca6777fa8f"
        }}>{`057b3dc`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-documentation-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-documentation-3",
        "aria-label": " documentation 3 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`📝 Documentation`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`new-visualisation:`}</strong>{` F-UJI assessment results (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/1ebfc290b19dda3be9519cdd25e3ff72c3763951"
        }}>{`1ebfc29`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`new-visualisation:`}</strong>{` Why Garden? Attitudes and the perceived health benefits of home gardening (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e7e6426a78fc2841800b781fdabede30d2af3b79"
        }}>{`e7e6426`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "102-2022-01-20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#102-2022-01-20",
        "aria-label": "102 2022 01 20 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v1.0.1...v1.0.2"
      }}>{`1.0.2`}</a>{` (2022-01-20)`}</h3>
    <h3 {...{
      "id": "-testing-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-testing-1",
        "aria-label": " testing 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✅ Testing`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`blog:`}</strong>{` wait for navigation (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/b6899c5e4179da06a634f0731a8891c6f0e360f1"
        }}>{`b6899c5`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-continuous-integration-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-7",
        "aria-label": " continuous integration 7 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`add de-duplicate steps in workflows (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/fe69d7def5072a52fc98915358e2cc6a7b2d7e19"
        }}>{`fe69d7d`}</a>{`)`}</li>
      <li parentName="ul">{`chore commit type will no longer trigger a new release (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/a1a2e5a8aeb157c074fd9b4acd7599ee5fb5a6e5"
        }}>{`a1a2e5a`}</a>{`)`}</li>
      <li parentName="ul">{`include post-deploy e2e checks in the deploy workflow (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/40ccb6a46fdeecf7fbb1f49da38961859bee4ab2"
        }}>{`40ccb6a`}</a>{`)`}</li>
      <li parentName="ul">{`skip same content runs (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/400ecf69785f81fd775d1db81150e303fb1e7343"
        }}>{`400ecf6`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "101-2022-01-20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#101-2022-01-20",
        "aria-label": "101 2022 01 20 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a><a parentName="h3" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/compare/v1.0.0...v1.0.1"
      }}>{`1.0.1`}</a>{` (2022-01-20)`}</h3>
    <h3 {...{
      "id": "-continuous-integration-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-8",
        "aria-label": " continuous integration 8 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`attempt to fix the problem that unable to push to protected branches (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/737a5b5a2986c483ac441ddc40e9569c70ebea3c"
        }}>{`737a5b5`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "100-2022-01-19",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#100-2022-01-19",
        "aria-label": "100 2022 01 19 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`1.0.0 (2022-01-19)`}</h2>
    <h3 {...{
      "id": "-breaking-changes-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-breaking-changes-1",
        "aria-label": " breaking changes 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`⚠ BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`update framework to v3.9.1`}</li>
    </ul>
    <h3 {...{
      "id": "️-code-refactoring-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%B8%8F-code-refactoring-1",
        "aria-label": "️ code refactoring 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`♻️ Code Refactoring`}</h3>
    <ul>
      <li parentName="ul">{`add description for flowchart (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/54dee890e63c01cf77f4aa84573257becb2fe5bd"
        }}>{`54dee89`}</a>{`)`}</li>
      <li parentName="ul">{`download visualisation as png or svg (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/76688a5dd54f14b285934599304120b29302b4ed"
        }}>{`76688a5`}</a>{`)`}</li>
      <li parentName="ul">{`modify github actions and scripts (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/4876b6350397185af7eaf6f171496e2d90e24bce"
        }}>{`4876b63`}</a>{`)`}</li>
      <li parentName="ul">{`visualisation tag menu size (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/9580f5ec077dd983c173020f94b84b666314303b"
        }}>{`9580f5e`}</a>{`)`}</li>
      <li parentName="ul">{`ways to embed visualisation (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/2a38a322f7ba6059753d79974c847954254334f4"
        }}>{`2a38a32`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-documentation-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-documentation-4",
        "aria-label": " documentation 4 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`📝 Documentation`}</h3>
    <ul>
      <li parentName="ul">{`add docs for testing and GitHub Actions (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/80d8d3fd98654fe88db13096588315951a2b6d97"
        }}>{`80d8d3f`}</a>{`)`}</li>
      <li parentName="ul">{`add guidelines for contribute visualisation (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/52d04264acc222f8a65143376bafbe1f309394a8"
        }}>{`52d0426`}</a>{`)`}</li>
      <li parentName="ul">{`add intro blog post for visualisation page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/bf1e0210e637a2613f2fe49b78c95a991551f6d3"
        }}>{`bf1e021`}</a>{`)`}</li>
      <li parentName="ul">{`add two new visualisations (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/21314853d97a6122e911afd06defe2a276ef0ffe"
        }}>{`2131485`}</a>{`)`}</li>
      <li parentName="ul">{`blog posts grammar checks (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/102"
        }}>{`#102`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/375b6f23f55594ffaeabc679cbaa66aac8d34a52"
        }}>{`375b6f2`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/101"
        }}>{`#101`}</a></li>
      <li parentName="ul">{`correct png download path for visualisation (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8d7edf96c28aeec9d09adc9e7241aa6c14c79cb8"
        }}>{`8d7edf9`}</a>{`)`}</li>
      <li parentName="ul">{`edit visualisation items with correct embed code (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/c7b1262b2642644508c9311cf88f21ec6b493950"
        }}>{`c7b1262`}</a>{`)`}</li>
      <li parentName="ul">{`fix missing images in contribute visualisation docs (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/a404f4829d6a1a9adedd314aee9b85422681191e"
        }}>{`a404f48`}</a>{`)`}</li>
      <li parentName="ul">{`Grammarpolice2 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/101"
        }}>{`#101`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8631e69b2186f1347d5598baeb715fe7f13ef2bf"
        }}>{`8631e69`}</a>{`)`}</li>
      <li parentName="ul">{`Statistical modelling - Part 3 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e538dfc8cfa353c6c8aea57499e17141996f45a8"
        }}>{`e538dfc`}</a>{`)`}</li>
      <li parentName="ul">{`visualisation property typo (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/4258025e453dba84555085fb6a29d7c8ccf1f910"
        }}>{`4258025`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-bug-fixes-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes-7",
        "aria-label": " bug fixes 7 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`accessibility issues (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/46a70656d99414257ea60a56aec08dc46747f7d4"
        }}>{`46a7065`}</a>{`)`}</li>
      <li parentName="ul">{`accessibility issues for visualisations (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/c2a2c60e3f749d0a9a728b267995f52db2fbb0af"
        }}>{`c2a2c60`}</a>{`)`}</li>
      <li parentName="ul">{`cannot query event thumbnail (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/d8abb8300a01a7e9ce1ba8e413426da454bce087"
        }}>{`d8abb83`}</a>{`)`}</li>
      <li parentName="ul">{`css styling error (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/345e02e039bea98a8425709822621daa0fade45d"
        }}>{`345e02e`}</a>{`)`}</li>
      <li parentName="ul">{`header text for the visualisation page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/484bf994657e2052622cdeec644d010c37fd578e"
        }}>{`484bf99`}</a>{`)`}</li>
      <li parentName="ul">{`hide visualisation download & embed icons (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/b623584146fe3333463457f34a2899d1406d5ea6"
        }}>{`b623584`}</a>{`)`}</li>
      <li parentName="ul">{`katex style not found issue (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8e4a1e406c4a7c1a6960de059312f7cffb2a3184"
        }}>{`8e4a1e4`}</a>{`)`}</li>
      <li parentName="ul">{`learning path left and right arrows (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e73d3656ec1a244d1a5c10dfa1d98df071d671b8"
        }}>{`e73d365`}</a>{`)`}</li>
      <li parentName="ul">{`learning path sliding animation (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/d5df1a583ef7d0284dce5b1cbde49881728fc6de"
        }}>{`d5df1a5`}</a>{`)`}</li>
      <li parentName="ul">{`missing legend title for visualisation map (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/15d556af3a504020c0ad7a82da793401fe343d0e"
        }}>{`15d556a`}</a>{`)`}</li>
      <li parentName="ul">{`missing slash in the visualisation download link (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/a2dd95d01f54cbce4083f5ab129a4a69c0b0a2ef"
        }}>{`a2dd95d`}</a>{`)`}</li>
      <li parentName="ul">{`path prefix for QA (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/cd3f7262828d5bb88987a871f6aa777e548ee4e4"
        }}>{`cd3f726`}</a>{`)`}</li>
      <li parentName="ul">{`reference dependency functions in module (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/c11e4d033947328aab0e231b124c31181f388fca"
        }}>{`c11e4d0`}</a>{`)`}</li>
      <li parentName="ul">{`thumbail for the event page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/b907a86ed7c358dcb4a708887a4156cabf5ea579"
        }}>{`b907a86`}</a>{`)`}</li>
      <li parentName="ul">{`twitter card not rendering due to extra slash in the url (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e65ae9fa8b6afa5fd7656df14cf54422434d847d"
        }}>{`e65ae9f`}</a>{`)`}</li>
      <li parentName="ul">{`update new snapshot for search results (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/dbf782d1bd83e136bbe501975be8e784024078bd"
        }}>{`dbf782d`}</a>{`)`}</li>
      <li parentName="ul">{`update package.json version number (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/0c389724eb33df4571b2496b485a96cce477a465"
        }}>{`0c38972`}</a>{`)`}</li>
      <li parentName="ul">{`visualisation download link missing a slash (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/100"
        }}>{`#100`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/19f7cbed13e092869d94f1b68821a62e73e292c9"
        }}>{`19f7cbe`}</a>{`)`}</li>
      <li parentName="ul">{`visualisation responsive problem (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/fb0e5a17bde73046ecc3633e5c7af269fae88ca7"
        }}>{`fb0e5a1`}</a>{`)`}</li>
      <li parentName="ul">{`visualisation tag sorting error (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/659d1257729c841772319d8f4286d0dc0246e9be"
        }}>{`659d125`}</a>{`)`}</li>
      <li parentName="ul">{`visualisation thumbnail and styles (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/3177c061e6ec8eb223c0c86ff79aedacd6d17b61"
        }}>{`3177c06`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-testing-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-testing-2",
        "aria-label": " testing 2 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✅ Testing`}</h3>
    <ul>
      <li parentName="ul">{`add configuration files for QA and LIVE environment (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/9892c72c31423c925ee4e753a7a7547859d526ba"
        }}>{`9892c72`}</a>{`)`}</li>
      <li parentName="ul">{`add e2e tests for blog and visualisation pages (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/168"
        }}>{`#168`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/48bf97e54e0e5014920b7ac9b3e133bf4a042666"
        }}>{`48bf97e`}</a>{`)`}</li>
      <li parentName="ul">{`fix blog button url (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/ac89fea0e7d32e952acd2e8390e4c545355810fa"
        }}>{`ac89fea`}</a>{`)`}</li>
      <li parentName="ul">{`fix navigation between blog pages (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/52ca11c9896a9082bb8963c96b34e112e3e3b795"
        }}>{`52ca11c`}</a>{`)`}</li>
      <li parentName="ul">{`fix timezone (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/11344b9d5d54b4cc6fbc0d40a41f9fe51af990e0"
        }}>{`11344b9`}</a>{`)`}</li>
      <li parentName="ul">{`fix timezone test (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/801ed9af115f8597efd092777d9ac3dc573eb839"
        }}>{`801ed9a`}</a>{`)`}</li>
      <li parentName="ul">{`prepare post-deploy test scripts (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/07f5be2cff7d708d06c59e6e37ddbca2173dcb9f"
        }}>{`07f5be2`}</a>{`)`}</li>
      <li parentName="ul">{`remove extra slash (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/790174d573893795271edde3873a35c3f57efc7d"
        }}>{`790174d`}</a>{`)`}</li>
      <li parentName="ul">{`remove Webkit (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/1c3583fcba3959863ee12e7c65f3295ab36d3a4a"
        }}>{`1c3583f`}</a>{`)`}</li>
      <li parentName="ul">{`rename test extensions (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/9c2e25b56b10e24e75490e97bd7005f0fb733b87"
        }}>{`9c2e25b`}</a>{`)`}</li>
      <li parentName="ul">{`update header snapshot (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/a53c2a92c133b32203aa63e0a1949e547bf85f83"
        }}>{`a53c2a9`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-features-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-features-1",
        "aria-label": " features 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✨ Features`}</h3>
    <ul>
      <li parentName="ul">{`add embed visualisation function (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/d7d847a8aa041fa174dc3f59e8b4a88123f3d031"
        }}>{`d7d847a`}</a>{`)`}</li>
      <li parentName="ul">{`add full visualisation cat&tag menu (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/16804197bd042323bdc0c27109245458a46cd434"
        }}>{`1680419`}</a>{`)`}</li>
      <li parentName="ul">{`add go back button for visualisation item (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/f7ab3f7f5c6c4415cb23494c43af0bfd233eb698"
        }}>{`f7ab3f7`}</a>{`)`}</li>
      <li parentName="ul">{`add support for twitter card (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/aecd8e3e0cb105afa3e90f9d5e9ca084643721e2"
        }}>{`aecd8e3`}</a>{`)`}</li>
      <li parentName="ul">{`add the changelog page and the markdown file (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/87542e5e6b9ae721a780188be65c722ce0747bfb"
        }}>{`87542e5`}</a>{`)`}</li>
      <li parentName="ul">{`complete visualisation tag menu (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/8457be621d10f82e6bb6cd50308107c415a402f6"
        }}>{`8457be6`}</a>{`)`}</li>
      <li parentName="ul">{`redesign search results (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/aeef90d7eab7eaaa0bffc722e572326d879ae9e5"
        }}>{`aeef90d`}</a>{`)`}</li>
      <li parentName="ul">{`statistical test flow chart (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/106"
        }}>{`#106`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/d2ea6722649aadfdb3a454400454fbb7b656732b"
        }}>{`d2ea672`}</a>{`)`}</li>
      <li parentName="ul">{`word cloud for visualisation page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/b97ef90d5c6a64a49b156cae685f73db52e8630f"
        }}>{`b97ef90`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-builddependencies-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-8",
        "aria-label": " builddependencies 8 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul">{`add semantic-release package to the codebase (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/b1a1ff096dbb2d8c591a33794ccdbec180551bde"
        }}>{`b1a1ff0`}</a>{`)`}</li>
      <li parentName="ul">{`add semantic-version package `}{`[skip ci]`}{` (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/21b1267376665581fbac752ef052ecaa6771a99c"
        }}>{`21b1267`}</a>{`)`}</li>
      <li parentName="ul">{`change version (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/75ca6e6f76e1067def328e744296fe4a799af5ed"
        }}>{`75ca6e6`}</a>{`)`}</li>
      <li parentName="ul">{`remove post-deploy workflow (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/69dd42c0460ea3cea5b10fd042aa0447d487e02f"
        }}>{`69dd42c`}</a>{`)`}</li>
      <li parentName="ul">{`update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/31f14bde0f546a2c47802c83dc1a809336ad12ad"
        }}>{`31f14bd`}</a>{`)`}</li>
      <li parentName="ul">{`update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/173"
        }}>{`#173`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/757bd3083d26016832334da00149669459dcc9e9"
        }}>{`757bd30`}</a>{`)`}</li>
      <li parentName="ul">{`upgrade package dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/c14650bd142b111fb411fa003346039b06858039"
        }}>{`c14650b`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-continuous-integration-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-9",
        "aria-label": " continuous integration 9 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`add node option to deploy workflow (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/1cd5c5aaf4e84d9a0dfd9b39d106b4ce4f78a966"
        }}>{`1cd5c5a`}</a>{`)`}</li>
      <li parentName="ul">{`add npm cache for workflows (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/ff5f39bbf47a63c4589d8d96219c5412193bbbd9"
        }}>{`ff5f39b`}</a>{`)`}</li>
      <li parentName="ul">{`change host runner for deployment (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/122d451b67502a5bad17488fd865fc639cdd7527"
        }}>{`122d451`}</a>{`)`}</li>
      <li parentName="ul">{`correct cache version to v2 (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/9d391f89677d2615118ecdb5bba6e8946f6398a2"
        }}>{`9d391f8`}</a>{`)`}</li>
      <li parentName="ul">{`deploy new changes to the QA site (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/issues/159"
        }}>{`#159`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/ff148c931f86824988f38d4328ae27fb60d50068"
        }}>{`ff148c9`}</a>{`)`}</li>
      <li parentName="ul">{`fix git error (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/a1e771b2966826e043ab51286332bec09a939bbd"
        }}>{`a1e771b`}</a>{`)`}</li>
      <li parentName="ul">{`fix scripts (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/917b20e8bc816e88d29058d06f620bdebee50a41"
        }}>{`917b20e`}</a>{`)`}</li>
      <li parentName="ul">{`fix scripts (2) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/1d7fa33b6b621da9780c4a2b1ea4a269c65ff1c0"
        }}>{`1d7fa33`}</a>{`)`}</li>
      <li parentName="ul">{`fix scripts (3) (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/708aac4796479d0ba221a5943c95a7e51d98c459"
        }}>{`708aac4`}</a>{`)`}</li>
      <li parentName="ul">{`include semantic-release stage in the Deploy workflow (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/2a26c49c479f0b5e58917270a48983506c360a49"
        }}>{`2a26c49`}</a>{`)`}</li>
      <li parentName="ul">{`increase node memory (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/67ae2ef39fb6f0a6cd2aef1a4061e43051623735"
        }}>{`67ae2ef`}</a>{`)`}</li>
      <li parentName="ul">{`move the test workflow into a seperate file (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/e2ab118af66267dc59b264f06db237a4bdfc37b7"
        }}>{`e2ab118`}</a>{`)`}</li>
      <li parentName="ul">{`remove CNAME on QA deployment (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/56aa7e23e0d67ea70b515f228015cbb52b9efd62"
        }}>{`56aa7e2`}</a>{`)`}</li>
      <li parentName="ul">{`remove update snapshot option (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/c0b23b6ea29b7e853921e4e69f34552e1b5e00b5"
        }}>{`c0b23b6`}</a>{`)`}</li>
      <li parentName="ul">{`replace GITHUB_TOKEN (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/85810ec891cad49931e12b254a630f74da58c72e"
        }}>{`85810ec`}</a>{`)`}</li>
      <li parentName="ul">{`replace ubuntu with windows for consistent snapshots (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/31c1d1dd5f998f82e084febfce21e6bb09e53548"
        }}>{`31c1d1d`}</a>{`)`}</li>
      <li parentName="ul">{`test husky hook (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/863c3559691450b4aa5b36a056aab46185e5e3e6"
        }}>{`863c355`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "2021-11-19",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-11-19",
        "aria-label": "2021 11 19 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-11-19`}</h3>
    <h3 {...{
      "id": "-features-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-features-2",
        "aria-label": " features 2 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✨ Features`}</h3>
    <ul>
      <li parentName="ul">{`add InfoVis visualisation page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/196"
        }}>{`#196`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "️-code-refactoring-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%B8%8F-code-refactoring-2",
        "aria-label": "️ code refactoring 2 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`♻️ Code Refactoring`}</h3>
    <ul>
      <li parentName="ul">{`add links to the visualisation page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/199"
        }}>{`#199`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "2021-11-17",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-11-17",
        "aria-label": "2021 11 17 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-11-17`}</h3>
    <h3 {...{
      "id": "-features-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-features-3",
        "aria-label": " features 3 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✨ Features`}</h3>
    <ul>
      <li parentName="ul">{`Introduce the visualisation page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/0c389724eb33df4571b2496b485a96cce477a465"
        }}>{`0c38972`}</a>{`)`}</li>
    </ul>
    <p>{`📝 Documentation`}</p>
    <ul>
      <li parentName="ul">{`Fix blog posts typos (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/93c8423e62253319dad651b05d7df3f7ab95e9f7"
        }}>{`93c8423`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "️-code-refactoring-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%B8%8F-code-refactoring-3",
        "aria-label": "️ code refactoring 3 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`♻️ Code Refactoring`}</h3>
    <ul>
      <li parentName="ul">{`re-design search results (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/f5aa86c1eabc796df85c119cb0aa7836ec082f6e"
        }}>{`f5aa86c`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "2021-11-08",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-11-08",
        "aria-label": "2021 11 08 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-11-08`}</h3>
    <p>{`📝 Documentation`}</p>
    <ul>
      <li parentName="ul">{`docs: add intro blog post for visualisation page (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/bf1e0210e637a2613f2fe49b78c95a991551f6d3"
        }}>{`bf1e02`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "2021-10-21",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-10-21",
        "aria-label": "2021 10 21 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-10-21`}</h3>
    <h3 {...{
      "id": "-documentation-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-documentation-5",
        "aria-label": " documentation 5 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`📝 Documentation`}</h3>
    <ul>
      <li parentName="ul">{`Visualisation: marine fishes near extinction (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/1d4001112b47054b5da4361ed14a852c6972f1c5"
        }}>{`1d4001`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "2021-10-19",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-10-19",
        "aria-label": "2021 10 19 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-10-19`}</h3>
    <h3 {...{
      "id": "-documentation-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-documentation-6",
        "aria-label": " documentation 6 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`📝 Documentation`}</h3>
    <ul>
      <li parentName="ul">{`ParaView blog post (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/commit/dd1dc261876825005d7ccbf69248ff37aaabc527"
        }}>{`dd1dc2`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "2021-10-14",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-10-14",
        "aria-label": "2021 10 14 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-10-14`}</h3>
    <h3 {...{
      "id": "-builddependencies-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-builddependencies-9",
        "aria-label": " builddependencies 9 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔨 Build/Dependencies`}</h3>
    <ul>
      <li parentName="ul">{`build: change ubuntu to windows in github actions (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/173"
        }}>{`#173`}</a>{`)`}</li>
      <li parentName="ul">{`build: update dependencies (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/173"
        }}>{`#173`}</a>{`)`}</li>
      <li parentName="ul">{`build: replace babel/core by @babel/core. Use babel-gatsby-preset to speed up production build (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/173"
        }}>{`#173`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-continuous-integration-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-10",
        "aria-label": " continuous integration 10 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">{`ci: update script (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/173"
        }}>{`#173`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "-testing-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-testing-3",
        "aria-label": " testing 3 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✅ Testing`}</h3>
    <ul>
      <li parentName="ul">{`test: increase timeout for navigation (`}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/173"
        }}>{`#173`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "2021-10-14-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-10-14-1",
        "aria-label": "2021 10 14 1 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-10-14`}</h3>
    <h3 {...{
      "id": "-testing-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-testing-4",
        "aria-label": " testing 4 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`✅ Testing`}</h3>
    <ul>
      <li parentName="ul">{`test: add image snapshot `}<a parentName="li" {...{
          "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/171"
        }}>{`#171`}</a></li>
    </ul>
    <p>{`Extend timeout limit.
Add tests for blog and blog posts.`}</p>
    <ul>
      <li parentName="ul">{`test: fix parallel performance issue`}</li>
    </ul>
    <p>{`Ignore e2e tests for Firefox.
Ignore main.e2e.js.
Remove reset functions in each test file.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`test: test shorter blog post`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`test: detach blog navigation from blog post tests`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "2021-09-24",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-09-24",
        "aria-label": "2021 09 24 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-09-24`}</h3>
    <p>{`Merged by pull request `}<a parentName="p" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/162"
      }}>{`#162`}</a></p>
    <h3 {...{
      "id": "-documentation-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-documentation-7",
        "aria-label": " documentation 7 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`📝 Documentation`}</h3>
    <ul>
      <li parentName="ul">{`visualisation: Improve rick cooking`}</li>
      <li parentName="ul">{`vis: initial layout and set up`}</li>
      <li parentName="ul">{`vis: complete visualisation`}</li>
    </ul>
    <h3 {...{
      "id": "-bug-fixes-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-bug-fixes-8",
        "aria-label": " bug fixes 8 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🐛 Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`fix: shorten text function handle empty string`}</li>
    </ul>
    <h3 {...{
      "id": "2021-09-20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2021-09-20",
        "aria-label": "2021 09 20 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`2021-09-20`}</h3>
    <p>{`Merged by pull request `}<a parentName="p" {...{
        "href": "https://github.com/researchdata-sheffield/dataviz-hub2/pull/159"
      }}>{`#159`}</a></p>
    <h3 {...{
      "id": "-continuous-integration-11",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-continuous-integration-11",
        "aria-label": " continuous integration 11 permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`🔧 Continuous Integration`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`ci: add script for deploy new commits to QA
Merge test script into CI.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ci: remove test workflow`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`ci: fix serve script`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      